<template>
  <div>
    <app-page-detail page-title="Edit Vendor" page-toolbar-title="Detail Vendor">
      <template #toolbar>
        <action-button :data-detail="data_detail" @refresh-detail="refreshDetail()" @btn-save-action="saveData()"></action-button>
      </template>
      <v-row>
        <v-col cols="12" lg="12">
          <v-row>
            <v-col cols="12" md="12">
              <card-expansion title="General" class="mb-3" :loading="loading">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-autocomplete outlined dense label="Kategori" v-model="vendor.category" :error-messages="formErrors.category" :items="vendorCategories" return-object required hide-details class="mb-2"></v-autocomplete>
                    <v-text-field dense v-model="vendor.category" placeholder="Category" label="Category" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense v-model="vendor.name" placeholder="Name" label="Name" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense v-model="vendor.business_fields" placeholder="Business Fields" label="Business Fields" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense v-model="vendor.npwp" placeholder="Business Fields" label="Business Fields" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field dense v-model="vendor.phone_no" placeholder="Phone No." label="Phone No." outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly style="background-color: #ddd;" v-model="vendor.email" placeholder="E-Mail" label="E-Mail" hide-details outlined class="mb-2"></v-text-field>
                    <v-text-field dense v-model="vendor.website" placeholder="Website" label="Website" hide-details outlined class="mb-2"></v-text-field>
                  </v-col>
                </v-row>
              </card-expansion>
              <card-expansion title="Address" class="mb-3" :loading="loading">
                <v-row>
                  <v-col cols="12" md="6">
                    <!-- <v-text-field dense v-model="vendor.country_name" placeholder="Country Name" label="Country Name" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense v-model="vendor.province_name" placeholder="Province Name" label="Province Name" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense v-model="vendor.regency_name" placeholder="Regency Name" label="Regency Name" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense v-model="vendor.district_name" placeholder="District Name" label="District Name" outlined hide-details class="mb-2"></v-text-field>
                     -->
                    <v-autocomplete outlined dense label="Negara" v-model="selected_country" :error-messages="formErrors.country_id" :items="listCountry" :loading="loadingCountry" item-text="name" item-value="id" @focus="getCountry()" return-object hide-details class="mb-2"></v-autocomplete>
                    <v-autocomplete outlined dense label="Provinsi" v-model="selected_province" :error-messages="formErrors.province_id" :items="listProvince" :loading="loadingProvince" item-text="name" item-value="id" @focus="getProvince()" return-object hide-details class="mb-2"></v-autocomplete>
                    <v-autocomplete outlined dense label="Kabupaten/Kota" v-model="selected_regency" :error-messages="formErrors.regency_id" :items="listRegency" :loading="loadingRegency" item-text="name" item-value="id" @focus="getRegency()" return-object hide-details class="mb-2"></v-autocomplete>
                    <v-autocomplete outlined dense label="Kecamatan" v-model="selected_district" :error-messages="formErrors.district_id" :items="listDistricts" :loading="loadingDistrict" item-text="name" item-value="id" @focus="getDistrict()" return-object hide-details class="mb-2"></v-autocomplete>
                    <v-text-field dense v-model="vendor.post_code" placeholder="Post Code" label="Post Code" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-textarea dense v-model="vendor.address" placeholder="Address" label="Address" outlined rows="3" hide-details class="mb-2"></v-textarea>
                    <v-textarea dense v-model="vendor.address2" placeholder="Address2" label="Address2" outlined rows="3" hide-details class="mb-2"></v-textarea>
                  </v-col>
                </v-row>
              </card-expansion>
              <card-expansion title="PIC" class="mb-3" :loading="loading">
                <v-row class="mb-3">
                  <v-col cols="6" md="6">
                    <v-text-field dense v-model="vendor.pic_name" placeholder="PIC Name" label="PIC Name" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense v-model="vendor.pic_phone_no" placeholder="PIC Phone No." label="PIC Phone No." outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly style="background-color: #ddd;" v-model="vendor.pic_email" placeholder="PIC E-Mail" label="PIC E-Mail" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense v-model="vendor.pic_position" placeholder="PIC Position" label="PIC Position" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-text-field dense readonly style="background-color: #ddd;" v-model="vendor.username" placeholder="Username" label="Username" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly style="background-color: #ddd;" v-model="vendor.password" placeholder="Password" label="Password" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                </v-row>
              </card-expansion>
              <card-expansion title="Documents" class="mb-3" :loading="loading">
                <v-row class="mb-3">
                  <v-col cols="6" md="6">
                    <v-text-field dense readonly style="background-color: #ddd;" v-model="vendor.npwp" placeholder="NPWP" label="NPWP" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly style="background-color: #ddd;" v-model="vendor.npwp_src" placeholder="NPWP File" label="NPWP File" outlined hide-details class="mb-2" @click:append="goToExternalURL(vendor.npwp_src)" append-icon="mdi-download"></v-text-field>
                    <v-text-field dense readonly style="background-color: #ddd;" v-model="vendor.ktp_src" placeholder="KTP" label="KTP" outlined hide-details class="mb-2" @click:append="goToExternalURL(vendor.ktp_src)" append-icon="mdi-download"></v-text-field>
                    <v-text-field dense readonly style="background-color: #ddd;" v-model="vendor.company_profile_src" placeholder="Company Profile" label="Company Profile" outlined hide-details class="mb-2" @click:append="goToExternalURL(vendor.company_profile_src)" append-icon="mdi-download"></v-text-field>
                    <v-text-field dense readonly style="background-color: #ddd;" v-model="vendor.nib_src" placeholder="NIB" label="NIB" outlined hide-details class="mb-2" @click:append="goToExternalURL(vendor.nib_src)" append-icon="mdi-download"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-text-field dense readonly style="background-color: #ddd;" v-model="vendor.catalog_src" placeholder="Catalog" label="Catalog" outlined hide-details class="mb-2" @click:append="goToExternalURL(vendor.catalog_src)" append-icon="mdi-download"></v-text-field>
                    <v-text-field dense readonly style="background-color: #ddd;" v-model="vendor.certificate_src" placeholder="Cerificate" label="Cerificate" outlined hide-details class="mb-2" @click:append="goToExternalURL(vendor.certificate_src)" append-icon="mdi-download"></v-text-field>
                    <v-text-field dense readonly style="background-color: #ddd;" v-model="vendor.licensing_document_src" placeholder="Licensing" label="Licensing" outlined hide-details class="mb-2" @click:append="goToExternalURL(vendor.licensing_document_src)" append-icon="mdi-download"></v-text-field>
                  </v-col>
                </v-row>
              </card-expansion>
              <card-expansion title="Info" class="mb-3" :loading="loading">
                <v-row class="mb-3">
                  <v-col cols="6" md="6">
                    <v-text-field dense readonly style="background-color: #ddd;" v-model="vendor.registration_date" placeholder="Registration Date" label="Registration Date" type="datetime" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly style="background-color: #ddd;" v-model="vendor.status" placeholder="Status" label="Status" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-text-field dense v-model="vendor.nav_vendor_no_" placeholder="NAV Vendor No." label="NAV Vendor No." outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly style="background-color: #ddd;" v-model="user.is_active" v-if="(typeof user.is_active != 'undefined')" placeholder="User Active" label="User Active" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                </v-row>
              </card-expansion>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </app-page-detail>

  </div>
</template>

<script>
import ActionButton from './components/ActionButton.vue';
export default {
  components: {
    ActionButton
  },
  data() {
    return {
      id: null,
      loading: false,
      loading_send_approval: false,
      loading_reopen: false,
      loading_approve: false,
      loading_cancel: false,
      dialog: false,
      modal1: false,
      tab: null,
      data_detail: {},
      vendor: {},
      user: {},
      formErrors: {},
      selected_country: { id: 0, name: '' },
      selected_province: { id: 0, name: '' },
      selected_regency: { id: 0, name: '' },
      selected_district: { id: 0, name: '' },
      loadingCountry: false,
      loadingProvince: false,
      loadingRegency: false,
      loadingDistrict: false,
      listCountry: [],
      listProvince: [],
      listRegency: [],
      listDistricts: [],

      vendorCategories: [],
    };
  },
  watch: {
  },
  computed: {
    dtId() {
      return parseInt(this.$route.query.id);
    },
  },
  methods: {
    refreshDetail() {
      this.getDetail(true);
    },

    // showDialog() {
    //   this.getDetail(true);
    // },

    /**
     * getDetail
     * Method untuk mengambil data detail dokumen
     */
    async getDetail(refresh = false) {
      this.loading = true;
      this.notFound = false;
      this.showLoadingOverlay(true);

      if (refresh == true) this.AxiosStorageRemove("GET", "vendor/detail");

      this.showLoadingOverlay(true);


      var config = {
        params: {
          id: this.dtId,
        },
        cacheConfig: true,
      };
      await this.$axios
        .get("vendor/detail", config)
        .then((res) => {
          this.data_detail = res.data.data;
          this.vendor = res.data.data.vendor;
          this.user = res.data.data.user;

          this.selected_country.id = this.vendor.country_id;
          this.selected_province.id = this.vendor.province_id;
          this.selected_regency.id = this.vendor.regency_id;
          this.selected_district.id = this.vendor.district_id;
          this.selected_country.name = this.vendor.country_name;
          this.selected_province.name = this.vendor.province_name;
          this.selected_regency.name = this.vendor.regency_name;
          this.selected_district.name = this.vendor.district_name;

          this.listCountry.push(this.selected_country);
          this.listProvince.push(this.selected_province);
          this.listRegency.push(this.selected_regency);
          this.listDistricts.push(this.selected_district);

          this.showLoadingOverlay(false);

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    saveData() {
      this.showLoadingOverlay(true);

      var formData = new FormData();
      formData.append("id", this.vendor.id);
      formData.append("category", this.vendor.category);
      formData.append("business_fields", this.vendor.business_fields);
      formData.append("name", this.vendor.name);
      formData.append("phone_no", this.vendor.phone_no);
      formData.append("email", this.vendor.email);
      formData.append("website", this.vendor.website);
      formData.append("address", this.vendor.address);
      if (typeof this.selected_country.id != 'undefined') formData.append("country_id", this.selected_country.id);
      if (typeof this.selected_province.id != 'undefined') formData.append("province_id", this.selected_province.id);
      if (typeof this.selected_regency.id != 'undefined') formData.append("regency_id", this.selected_regency.id);
      if (typeof this.selected_district.id != 'undefined') formData.append("district_id", this.selected_district.id);
      if (typeof this.selected_country.name != 'undefined') formData.append("country_name", this.selected_country.name);
      if (typeof this.selected_province.name != 'undefined') formData.append("province_name", this.selected_province.name);
      if (typeof this.selected_regency.name != 'undefined') formData.append("regency_name", this.selected_regency.name);
      if (typeof this.selected_district.name != 'undefined') formData.append("district_name", this.selected_district.name);
      formData.append("post_code", this.vendor.post_code);
      formData.append("pic_name", this.vendor.pic_name);
      formData.append("pic_email", this.vendor.pic_email);
      formData.append("pic_phone_no", this.vendor.pic_phone_no);
      formData.append("pic_position", this.vendor.pic_position);
      formData.append("username", this.vendor.username);
      formData.append("password", this.vendor.password);
      formData.append("password_conf", this.vendor.password_conf);
      formData.append("npwp", this.vendor.npwp);
      formData.append("npwp_src", this.vendor.npwp_src);
      formData.append("ktp_src", this.vendor.ktp_src);
      formData.append("nib_src", this.vendor.nib_src);
      formData.append("company_profile_src", this.vendor.company_profile_src);
      formData.append("catalog_src", this.vendor.catalog_src);
      formData.append("certificate_src", this.vendor.certificate_src);
      formData.append("licensing_document_src", this.vendor.licensing_document_src);
      formData.append("nav_vendor_no_", this.vendor.nav_vendor_no_);
      this.$axios
        .post("vendor/update", formData)
        // eslint-disable-next-line no-unused-vars
        .then((res) => {
          this.showLoadingOverlay(false);
          this.showAlert(res.data.status, res.data.message);
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
          this.formErrors = error.response.data.data.errors;
        });
    },

    getVendorCategories() {
      this.$axios.get('/public/vendor-categories').then((res) => {
        this.vendorCategories = res.data.data;
      });
    },

    getCountry() {
      this.loadingCountry = true
      this.$axios.get('/public/country').then((res) => {
        this.listCountry = res.data.data;
        this.loadingCountry = false
      });
    },

    getProvince() {
      const config = {
        params: {
          country_id: this.selected_country.id,
        }
      }
      this.loadingProvince = true
      this.$axios.get('/public/province', config).then((res) => {
        this.listProvince = res.data.data;
        this.loadingProvince = false
      });
    },

    getRegency() {
      const config = {
        params: {
          country_id: this.selected_country.id,
          province_id: this.selected_province.id,
        }
      }
      this.loadingRegency = true;
      this.$axios.get('/public/regency', config).then((res) => {
        this.listRegency = res.data.data;
        this.loadingRegency = false;
      });
    },

    getDistrict() {
      const config = {
        params: {
          country_id: this.selected_country.id,
          province_id: this.selected_province.id,
          regency_id: this.selected_regency.id,
        }
      }
      this.loadingDistrict = true;
      this.$axios.get('/public/district', config).then((res) => {
        this.listDistricts = res.data.data;
        this.loadingDistrict = false;
      });
    },
  },

  beforeMount() {
    if (typeof this.$route.query.id !== "undefined") {
      this.id = parseInt(this.$route.query.id);
    } else {
      this.id = parseInt(this.$route.params.id);
    }
  },

  mounted() {
    this.requiredLogin();

    this.modulePermission("vendor", "modify", true);

    this.getDetail(true);

    this.getVendorCategories();
  },
};
</script>
<style scoped>
.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  vertical-align: top;
  padding-top: 5px;
}
</style>
