<template>
  <div class="d-flex">
    <app-button @click="refreshDetail()" title="Refresh" mdi-icon="mdi-refresh" :loading="loading"></app-button>

    <v-divider vertical class="mx-2"></v-divider>
    <app-button :disabled="!enableDetailBtn" @click="redirect('Purchasing.Vendor.Detail', { id: vendor.id })" title="Detail" mdi-icon="mdi-file-find"></app-button>
    <app-button :disabled="!enableEditBtn" @click="redirect('Purchasing.Vendor.Edit', { id: vendor.id })" title="Edit" mdi-icon="mdi-pencil"></app-button>
    <v-divider vertical class="mx-2"></v-divider>
    <app-button :disabled="!enableSaveBtn" @click="updateData()" title="Save" mdi-icon="mdi-content-save"></app-button>
    <app-button :disabled="!enableAcceptBtn" @click="accept()" title="Accept Vendor" mdi-icon="mdi-check-circle-outline" :loading="loading_accept"></app-button>
    <app-button :disabled="!enableBlockBtn" @click="blockUnblock()" title="Block/Unblock Vendor" :mdi-icon="(!user.is_active) ? 'mdi-lock' : 'mdi-lock-open-variant'" :loading="loading_block"></app-button>
    <app-button :disabled="!enableDeleteBtn" @click="deleteData()" title="Delete" mdi-icon="mdi-delete" :loading="loading_delete"></app-button>
  </div>
</template>

<script>
export default {
  props: {
    dataDetail: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      loading: false,
      loading_delete: false,
      loading_send_approval: false,
      loading_reopen: false,
      loading_close_document: false,
      loading_approve: false,
      // loading_receive: false,
      loading_accept: false,
      loading_block: false,
      loading_attachment: false,
      loading_reject: false,
      loading_unsubmit: false,
      loading_review: false,
      data_detail: {},
    }
  },
  watch: {
    dataDetail() {
      this.data_detail = this.dataDetail;
    }
  },
  computed: {
    vendor() {
      return (typeof this.dataDetail.vendor != 'undefined') ? this.dataDetail.vendor : {};
    },
    user() {
      return (typeof this.dataDetail.user != 'undefined') ? this.dataDetail.user : {};
    },
    enableDetailBtn() {
      if (this.$route.name !== 'Purchasing.Vendor.Detail') {
        return true;
      }
      return false;
    },
    enableAcceptBtn() {
      if (this.vendor.status == 'NEW') {
        return true;
      }
      return false;
    },
    enableBlockBtn() {
      if (!this.user.exists) {
        return false;
      }
      return true;
    },
    enableEditBtn() {
      if (this.$route.name !== 'Purchasing.Vendor.Edit') {
        return true;
      }
      return false;
    },
    enableSaveBtn() {
      if (this.$route.name === 'Purchasing.Vendor.Edit') {
        return true;
      }
      return false;
    },
    enableDeleteBtn() {
      if (this.vendor.status == 'NEW') {
        return true;
      }
      return true;
    },
  },
  methods: {

    refreshDetail() {
      this.$emit('refresh-detail')
    },

    /**
     * accept
     * Method untuk menyetujui vendor
     * Vendor harus berstatus NEW
     * Vendor akan di buatkan user 
     */
    accept() {
      let post = async () => {
        this.loading_accept = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", this.vendor.id);
        await this.$axios
          .post("vendor/accept", formData)
          .then((res) => {
            this.loading_accept = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_accept = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Accept vendor?", post);
    },

    blockUnblock() {
      let post = async () => {
        this.loading_block = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", this.vendor.id);
        await this.$axios
          .post("vendor/block", formData)
          .then((res) => {
            this.loading_block = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_block = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      let msg = "Block vendor?";
      if (!this.enableBlockBtn) {
        msg = "Unblock vendor?";
      }
      this.showConfirm("Confirm", msg, post);
    },

    deleteData() {
      let post = async () => {
        this.loading_delete = true;
        this.showLoadingOverlay(true);
        await this.$axios
          .delete("vendor/delete/" + this.vendor.id)
          .then((res) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Delete vendor?", post);
    },

    updateData() {
      this.$emit("btn-save-action")
    },


  }
}
</script>